import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./index.css";
export default function Index() {
  return (
    <Container className="help-container">
      <div className="help-title-container">
        <h3 className="help-title text-center">Still have a question?</h3>
        <p className="help-description text-center">
          The Brilliant reasons Entrada should be your one-stop-shop!
        </p>
      </div>
      <Row>
        <Col md={2}></Col>
        <Col md={4}>
          <div className="help-for-sales"></div>
        </Col>
        <Col md={4}>
          <div className="help-for-support"></div>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}
