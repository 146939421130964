import React from "react";
import category from "../../images/category.jpg";
import { Container } from "react-bootstrap";
import { Breadcrumb } from "antd";
import "./index.css";
export default function Index(props) {
  return (
    <div>
      <Container fluid className="section_background"></Container>
      <Container className="section_transparent" fluid>
        <Container style={{ height: "100%", display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Breadcrumb
              items={[
                {
                  title: (
                    <a href={"/"} className={"breadcrum_parent_menu"}>
                      Home
                    </a>
                  ),
                },

                {
                  title: (
                    <p className="breadcrum_active">
                      {props.title.replace("%20", " ")}
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </Container>
      </Container>
    </div>
  );
}
