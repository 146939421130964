import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Product from "../Pages/Product";
import Category from "../Pages/Category";
import Activities from "../Pages/Activities";
import MostSelling from "../Pages/MostSelling";
import TopOffer from "../Pages/TopOffer";
import Login from "../Pages/Auth/Login/index";
import Signup from "../Pages/Auth/Signup/index";
import Cart from "../Pages/Cart/index";
export default function Index() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/product/:id" element={<Product />}></Route>
        <Route path="/category/:id/:name" element={<Category />}></Route>
        <Route path="/activities" element={<Activities />}></Route>
        <Route path="/most-selling" element={<MostSelling />}></Route>
        <Route path="/top-offer" element={<TopOffer />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
      </Routes>
    </Router>
  );
}
