import React, { useEffect, useState } from "react";
import "./index.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import Header from "../../Component/Header/index";
import Footer from "../../Component/Footer/index";
export default function Index() {
  const [data, setData] = useState([]);

  const getLocalCart = () => {
    let localData = localStorage.getItem("falcon_cart");
    if (localData === null) {
      console.log("cart yes");
    } else {
      let d = JSON.parse(localData);
      setData(d);
    }
  };

  const getPaymentURl = () => {
    const options = {
      method: "POST",

      headers: {
        accept: "application/json",
        Authorization: "Bearer sk-29f0f420-5c5b-445b-8fc4-67cfdea422c5",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        title: "Chocolate Box - Small",
        description: "12pcs Chocolate Box",
        active: true,
        return_url: "https://myawesomewebsite.com/paymentSuccess",
        failure_return_url: "https://failureurl.com/paymentFailure",
        processing_fee_percentage: 3,
        amount: 119.99,
        amount_currency: "AED",
        link_type: "standalone",
        enable_tabby: false,
        enable_message: false,
        enable_tips: false,
        save_card: "off",
        enable_customer_details: false,
        enable_quantity: false,
        enable_qr_code: false,
        send_customer_receipt: false,
        hold_and_charge_later: false,
      }),
    };

    fetch(
      "https://sandbox.dev.business.mamopay.com/manage_api/v1/links",
      options
    )
      .then((response) => response.json())
      .then((response) => console.log("res======>", response))
      .catch((err) => console.error("error======>", err));
  };

  useEffect(() => {
    getLocalCart();
  }, []);
  useEffect(() => {
    getPaymentURl();
  }, []);

  return (
    <Container fluid>
      <Header />
      <Container className="cart-container">
        <Row>
          <Col md={8}>
            {data.map((item) => {
              return (
                <Container className="cart_mainContainer">
                  <Row>
                    <Col md={2} className="cart-product-img-holder">
                      <Image src={item.image} className="cart-product-image" />
                    </Col>
                    <Col md={6}>
                      <br></br>

                      <span className="cart-product-title">{item.pname}</span>
                      <br></br>
                      <span className="cart-product-normal">
                        Adult {item.adult}
                      </span>
                      <br></br>
                      <span className="cart-product-normal">
                        Child {item.child}
                      </span>
                      <br></br>
                      <span className="cart-product-normal">
                        Booking Date 12/08/2024
                      </span>
                      <br></br>
                      <br></br>
                      <Row>
                        <br></br>
                        <br></br>
                        <Col md={4}>
                          <span className="cart-product-normal">
                            Update Booking
                          </span>
                        </Col>
                        <Col md={4} className="text-center">
                          <span className="cart-product-normal">Remove</span>
                        </Col>
                        <Col md={4}>
                          <span className="cart-product-normal">
                            Move to wishlist
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      <br></br>
                      <span className="cart-product-title">AED 99/-</span>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                </Container>
              );
            })}
          </Col>
          <Col md={4} className="cart-payment-section"></Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}
