import React, { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { falconApiCalling } from "../API/falconApiCalling";
import "./index.css";
export default function Index() {
  const [images, setImages] = useState([]);
  const [productId, setProductId] = useState(
    window.location.pathname.split("/")[2]
  );
  const getProductImages = () => {
    let params = { action: "GET_PRODUCT_IMAGES", pid: productId };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setImages(res);
      }
    });
  };

  useEffect(() => {
    getProductImages();
  }, []);

  const renderItem = (item, index) => {
    return (
      <section>
        <Image src={item.original} fluid className="originalImage" />
      </section>
    );
  };
  const renderThumbInner = (item, index) => {
    return (
      <section>
        <Image src={item.thumbnail} fluid className="thumbnailClass" />
      </section>
    );
  };

  return (
    <Container fluid>
      <div className={"image_gallery_product"}>
        {images.length > 0 ? (
          <ImageGallery
            items={images}
            thumbnailPosition={"left"}
            originalWidth={"75%"}
            originalHeight={"200px"}
            thumbnailClass={"thumbnailClass"}
            renderItem={renderItem}
            renderThumbInner={renderThumbInner}
            useTranslate3D={true}
          />
        ) : null}
      </div>
    </Container>
  );
}
