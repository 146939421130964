import React, { useState } from "react";
import one from "../../images/one.png";
import two from "../../images/two.png";
import three from "../../images/three.png";
import four from "../../images/four.png";
import wishlist from "../../images/wishlist.png";
import star from "../../images/star.png";
import clock from "../../images/clock.png";
import checkmark from "../../images/checkmark.png";
import { Col, Container, Image, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "./index.css";
import { Button } from "antd";
import badge from "../../images/badge.png";
import { Card, Divider } from "antd";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function Index(props) {
  const [testimonials, setTestimonials] = useState([
    {
      id: 1,
      badge: badge,
      name: "Brooklyn Simmons",
      title: "Exceptional",
      time: "Reviewed 23rd, November",
      review:
        "Dolor elit voluptate cupidatat in eiusmod. Eiusmod ex eu incididunt etile pariatur dolor mollit reprehenderit magna tempor ex minim velit sunt do.",
    },
    {
      id: 2,
      badge: badge,
      name: "Brooklyn Simmons",
      title: "Exceptional",
      time: "Reviewed 23rd, November",
      review:
        "Dolor elit voluptate cupidatat in eiusmod. Eiusmod ex eu incididunt etile pariatur dolor mollit reprehenderit magna tempor ex minim velit sunt do.",
    },
    {
      id: 3,
      badge: badge,
      name: "Brooklyn Simmons",
      title: "Exceptional",
      time: "Reviewed 23rd, November",
      review:
        "Dolor elit voluptate cupidatat in eiusmod. Eiusmod ex eu incididunt etile pariatur dolor mollit reprehenderit magna tempor ex minim velit sunt do.",
    },
    {
      id: 4,
      badge: badge,
      name: "Brooklyn Simmons",
      title: "Exceptional",
      time: "Reviewed 23rd, November",
      review:
        "Dolor elit voluptate cupidatat in eiusmod. Eiusmod ex eu incididunt etile pariatur dolor mollit reprehenderit magna tempor ex minim velit sunt do.",
    },
  ]);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    console.log(rest);
    return (
      <div className="carousel-button-group">
        {" "}
        <Button
          className={currentSlide === 0 ? "disable" : "enable"}
          onClick={() => previous()}
        >
          {"<"}
        </Button>
        <Button className="enable" onClick={() => next()}>
          {">"}
        </Button>
      </div>
    );
  };
  return (
    <Container className="category-container">
      <div>
        <h1>{props.title}</h1>
        <p>{props.description}</p>
      </div>
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container-product"
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        itemClass="carousel-item-padding-40-px"
        customButtonGroup={<ButtonGroup />}
        autoPlay={true}
      >
        {testimonials.map((item) => {
          return (
            <div
              md={4}
              className="item-container-tetimonials"
              style={{ padding: "10px", minHeight: "160px" }}
            >
              <Card
                title={
                  <div className="testimonial-item-header">
                    <Image src={item.badge} className="testimonials-badge" />
                    <p className="testimonials-name">{item.name}</p>
                    <div style={{ position: "absolute", right: "10px" }}>
                      {" "}
                      <Image src={star} className={"star-icon"} />
                    </div>
                  </div>
                }
                style={{
                  width: 400,
                }}
              >
                <div className="testimonials-title-holder">
                  <h3 className="testimonilas-title">{item.title}</h3>
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "75px",
                    }}
                  >
                    <p>{item.time}</p>
                  </div>
                </div>
                <Divider />
                <div className="testimonials-description">
                  <p className="testimonials-review">{item.review}</p>
                </div>
              </Card>
            </div>
          );
        })}
      </Carousel>
    </Container>
  );
}
