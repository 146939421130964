import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Form, Button, Image } from "react-bootstrap";
import logo from "../../images/logo.png";
import { TiShoppingCart } from "react-icons/ti";

import "./index.css";
import { Badge } from "antd";
export default function Index() {
  const [cartNum, setCartNum] = useState(0);

  const getCart = () => {
    let product = localStorage.getItem("falcon_cart");
    if (product === null) {
      console.log("yes", product);
    } else {
      if (Array.isArray(product) && product !== null) {
      } else {
        setCartNum(JSON.parse(product).length);
        console.log(JSON.parse(product));
      }
    }
  };

  useEffect(() => {
    getCart();
  });

  return (
    <Navbar expand="lg" className="bg-body header">
      <Container className="header-container">
        <Navbar.Brand href="/">
          <Image src={logo} fluid className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto nav">
            <Nav.Link href="/activities">Activities</Nav.Link>
            <Nav.Link href="/most-selling">Most Selling</Nav.Link>
            <Nav.Link href="/top-offer">Top Offers</Nav.Link>
          </Nav>
          <Nav className="d-flex nav">
            <Nav.Link href="/cart">
              <Badge count={cartNum} showZero={false}>
                <TiShoppingCart size={28} />
              </Badge>
            </Nav.Link>
            <Nav.Link href="/login">Login</Nav.Link>
            <Nav.Link href="/signup" className="signup_btn">
              Signup
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
