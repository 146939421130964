import React, { useState } from "react";
import Header from "../../Component/Header/index";
import Footer from "../../Component/Footer";
import { Container } from "react-bootstrap";
import Section from "../../Component/Section";
import CategoryProduct from "../../Component/CategoryProduct";
export default function Index() {
  const [title, setTitle] = useState(window.location.pathname.split("/")[3]);
  const [cid, setcid] = useState(window.location.pathname.split("/")[2]);
  return (
    <Container fluid style={{ padding: "0px", margin: "0px" }}>
      <Header />
      <Section title={title} />
      <CategoryProduct cid={cid} />
      <Footer />
    </Container>
  );
}
