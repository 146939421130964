import React, { useEffect, useState } from "react";
import { falconApiCalling } from "../../../Component/API/falconApiCalling";
import { Col, Container, Image, Row } from "react-bootstrap";
import document from "../../../images/document.png";
import activity_icon from "../../../images/activity.png";
import age_icon from "../../../images/age.png";
import duration_icon from "../../../images/duration.png";
import group_icon from "../../../images/group.png";
import physical_icon from "../../../images/physical.png";
import season_icon from "../../../images/season.png";
import "./index.css";
export default function Overview(props) {
  const { pid } = props;
  const [overview, setOverview] = useState("");
  const [duration, setDuration] = useState("");
  const [activity, setActivity] = useState("");
  const [physical, setPhysical] = useState("");
  const [size, setSize] = useState("");
  const [age, setAge] = useState("");
  const [season, setSeason] = useState("");
  useEffect(() => {
    let params = { action: "GET_PRODUCT_OVERVIEW", pid: pid };
    falconApiCalling(params).then((res) => {
      if (res.status === "success") {
        setOverview(res.data.overview);
        setDuration(res.data.duration);
        setActivity(res.data.activity);
        setPhysical(res.data.physical);
        setSize(res.data.group_size);
        setAge(res.data.age);
        setSeason(res.data.season);
      }
    });
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md={1}>
          <Image src={document} />
        </Col>
        <Col md={11}>
          <p className="overview-text">{overview}</p>
          <Row>
            <Col md={4}>
              <p className="overview_options">Duration</p>
              <div className="overview_innerbox">
                <Image src={duration_icon} />
                <span className="overview_inner_desc">
                  {`${duration}`} Hours
                </span>
              </div>
            </Col>
            <Col md={4}>
              <p className="overview_options">Activity</p>
              <div className="overview_innerbox">
                <Image src={activity_icon} />
                <span className="overview_inner_desc">{`${activity}`}</span>
              </div>
            </Col>
            <Col md={4}>
              <p className="overview_options">Physical</p>
              <div className="overview_innerbox">
                <Image src={physical_icon} />
                <span className="overview_inner_desc">{`${physical}`}</span>
              </div>
            </Col>
            <Col md={4}>
              <p className="overview_options">Group Size</p>
              <div className="overview_innerbox">
                <Image src={group_icon} />
                <span className="overview_inner_desc">{`${size}`}</span>
              </div>
            </Col>
            <Col md={4}>
              <p className="overview_options">Age</p>
              <div className="overview_innerbox">
                <Image src={age_icon} />
                <span className="overview_inner_desc">{`${age}`}</span>
              </div>
            </Col>
            <Col md={4}>
              <p className="overview_options">Season</p>
              <div className="overview_innerbox">
                <Image src={season_icon} />
                <span className="overview_inner_desc">{`${season}`}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
