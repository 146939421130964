import React, { useEffect } from "react";
import Header from "../../Component/Header/index";
import ProductBanner from "../../Component/ProductBanner/index";
import ProductDetail from "../../Component/ProductDetail/index";
import ProductDescription from "../../Component/ProductDescription/index";
import Footer from "../../Component/Footer/index";
import ProductCalculator from "../../Component/ProductCalculator/index";
import TalkToExpert from "../../Component/TalkToExpert";
import "./Product.css";
import { Col, Container, Row } from "react-bootstrap";
export default function Index() {
  return (
    <Container fluid>
      <Header />
      <ProductBanner />
      <Container className="product-main-container">
        <Row>
          <Col md={9}>
            <ProductDetail />
            <ProductDescription />
          </Col>
          <Col md={3}>
            <ProductCalculator />
            <TalkToExpert />
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}
