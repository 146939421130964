import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./index.css";
import search_icon from "../../images/search.png";
import location_icon from "../../images/search_location.png";
import home_dropdown from "../../images/home_dropdown.png";
import home_cal from "../../images/home_calander.png";
import { Button, Select, DatePicker } from "antd";
export default function Index() {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <Container>
      <Row>
        <Col md={3} className="location-box">
          <h1 className="location-box-heading">Location</h1>
          <Select
            className="home-drop-down"
            showSearch
            style={{
              width: 200,
            }}
            placeholder="Enter your destination"
            optionFilterProp="label"
            suffixIcon={
              <Image
                src={location_icon}
                style={{ width: "15px", height: "17px" }}
              />
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "Not Identified",
              },
              {
                value: "2",
                label: "Closed",
              },
              {
                value: "3",
                label: "Communicated",
              },
              {
                value: "4",
                label: "Identified",
              },
              {
                value: "5",
                label: "Resolved",
              },
              {
                value: "6",
                label: "Cancelled",
              },
            ]}
          />
        </Col>
        <Col md={3} className="activity-box">
          <h1 className="activity-box-heading">Activity</h1>
          <Select
            className="home-drop-down"
            showSearch
            style={{
              width: 200,
            }}
            placeholder="Enter your activity"
            optionFilterProp="label"
            suffixIcon={
              <Image
                src={home_dropdown}
                style={{ width: "18px", height: "18px" }}
              />
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "Not Identified",
              },
              {
                value: "2",
                label: "Closed",
              },
              {
                value: "3",
                label: "Communicated",
              },
              {
                value: "4",
                label: "Identified",
              },
              {
                value: "5",
                label: "Resolved",
              },
              {
                value: "6",
                label: "Cancelled",
              },
            ]}
          />
        </Col>
        <Col md={3} className="date-box">
          <h1 className="date-box-heading">Date</h1>
          <DatePicker
            onChange={onChange}
            suffixIcon={
              <Image src={home_cal} style={{ width: "16px", height: "16px" }} />
            }
          />
        </Col>
        <Col md={3} className="search-button-box">
          <Button className="search-btn">
            <Image
              src={search_icon}
              className="search-icon"
              alt="search-icon"
            />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
