import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./index.css";
import { falconApiCalling } from "../API/falconApiCalling";
import { Select, Space, Form, DatePicker, Button } from "antd";
import calender from "../../images/calender.png";
import Counter from "../Counter/index";
import { ToastContainer, toast } from "react-toastify";
export default function Index() {
  const [pid, setPid] = useState(window.location.pathname.split("/")[2]);
  const [sharingList, setsharingList] = useState([
    {
      label: "Sharing Transfer (deira)",
      value: "Sharing Transfer (deira)",
    },
  ]);
  const [privateList, setprivateList] = useState([
    {
      label: "5 Seater (up to 5 persons)",
      value: "5 Seater",
    },
    {
      label: "9 Seater (up to 9 persons)",
      value: "9 Seater",
    },
  ]);
  const [typeList, setTypeList] = useState([]);
  const [filteredType, setFilteredType] = useState([]);
  const [adult, setAdult] = useState(0);
  const [child, setChild] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [transport, setTransport] = useState(null);
  const [product_name, setProduct_name] = useState(null);
  const [product_image, setProduct_image] = useState(null);

  const getProductType = () => {
    let params = { action: "GET_PRODUCT_TYPE_LIST", pid: pid };
    falconApiCalling(params).then((res) => {
      if (Array.isArray) {
        setTypeList(res);
        let arr = res.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setFilteredType(arr);
      }
    });
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  useEffect(() => {
    getProductType();
  }, []);

  const onChangeAdult = () => {
    setAdult(adult + 1);
    setTotal(adult + 1 + child);
    if (total > 5) {
    }
  };
  const onChangeChild = () => {
    setChild(child + 1);
    setTotal(adult + child + 1);
  };

  const downAdult = () => {
    if (adult === 0) {
    } else {
      setAdult(adult - 1);
      setTotal(adult - 1 + child);
    }
  };

  const downChild = () => {
    if (child === 0) {
    } else {
      setChild(child - 1);
      setTotal(adult + child - 1);
    }
  };
  console.log(child, adult);

  const addToCart = () => {
    const data = {
      pid: pid,
      pname: product_name,
      image: product_image,
      adult: adult,
      child: child,
      total: total,
      transport: transport,
    };
    let finalData = [data];
    let old = JSON.parse(localStorage.getItem("falcon_cart"));
    if (Array.isArray(old) && old != "null") {
      let available = old.filter((item) => item.pid === pid);
      if (available.length > 0) {
        toast.warning("Product already in cart");
      } else {
        let newArr = JSON.parse(localStorage.getItem("falcon_cart"));
        let newData = newArr.push(data);

        localStorage.setItem("falcon_cart", JSON.stringify(newArr));
        toast.success("Product Added to cart");
        window.location.href = "#";
      }
    } else {
      localStorage.setItem("falcon_cart", JSON.stringify(finalData));
      toast.success("Product Added to cart");
      window.location.href = "#";
    }
  };

  const getProductImages = () => {
    let params = { action: "GET_PRODUCT_IMAGES", pid: pid };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setProduct_image(res[0].original);
      }
    });
  };

  const getProductName = () => {
    let params = { action: "GET_PRODUCT_DETAIL", pid: pid };
    falconApiCalling(params).then((res) => {
      if (res.status === "success") {
        setProduct_name(res.product_name);
      }
    });
  };

  useEffect(() => {
    getProductImages();
  }, []);

  useEffect(() => {
    getProductName();
  }, []);

  return (
    <Container className="calculator_container">
      <Container className="price_container">
        <div style={{ padding: "0px" }}>
          <span className="max_price">AED 250</span>
          <Row>
            <Col md={5} style={{ padding: "0px" }}>
              <h2 className="discounted_price">AED 149</h2>
            </Col>
            <Col
              md={3}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
                padding: "0px",
              }}
            >
              <span className="per_person">Per Person</span>
            </Col>
            <Col
              md={4}
              style={{
                padding: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="off_percentage">
                <span className="off_text">20% Off</span>
              </div>
            </Col>
          </Row>
          <span className="note_description">
            *Price based on selections below.
          </span>
        </div>
      </Container>
      <Container className="form_container">
        <Form layout="vertical">
          <Form.Item label="Type" required tooltip="This is a required field">
            <Select placeholder={"Select Type"} options={filteredType} />
          </Form.Item>
          <Form.Item label="Dates" required tooltip="This is a required field">
            <DatePicker
              onChange={onChange}
              style={{ width: "100%" }}
              prefix={<Image src={calender} />}
            />
          </Form.Item>
          <Form.Item label="Adult">
            <Counter value={adult} add={onChangeAdult} minus={downAdult} />
          </Form.Item>
          <Form.Item label="Child">
            <Counter value={child} add={onChangeChild} minus={downChild} />
          </Form.Item>
          <Form.Item
            label="Sharing Transportation"
            required
            tooltip="This is a required field"
            disabled
          >
            <Select
              placeholder={"Sharing Transportation"}
              options={sharingList}
            />
          </Form.Item>
          <Form.Item
            label="Private Transportation"
            required
            tooltip="This is a required field"
          >
            <Select
              placeholder={"Private Transportation"}
              options={privateList}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" className="check_availability">
              Check Availability
            </Button>
          </Form.Item>
          {total > 0 ? (
            <Form.Item>
              <Button
                type="primary"
                className="check_availability"
                onClick={() => addToCart()}
              >
                Add To Cart
              </Button>
            </Form.Item>
          ) : null}
        </Form>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Container>
  );
}
