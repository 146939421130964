import React, { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "./index.css";
import { Button } from "antd";
import { falconApiCalling } from "../API/falconApiCalling";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function Index() {
  const [category, setCategory] = useState([]);

  const getCategory = () => {
    let params = { action: "GET_CATEGORY_LIST" };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        let cat = res.map((item) => {
          return {
            id: item.id,
            title: item.name,
            icon: item.icon,
            num: item.num,
            rating: item.rating,
          };
        });
        setCategory(cat);
      }
    });
  };

  useEffect(() => {
    getCategory();
  }, []);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    console.log(rest);
    return (
      <div className="carousel-button-group">
        {" "}
        <Button
          className={currentSlide === 0 ? "disable" : "enable"}
          onClick={() => previous()}
        >
          {"<"}
        </Button>
        <Button className="enable" onClick={() => next()}>
          {">"}
        </Button>
      </div>
    );
  };

  return (
    <Container className="category-container">
      <div>
        <h1>Select Category</h1>
        <p>Sost Brilliant reasons Entrada should be your one-stop-shop!</p>
      </div>
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        itemClass="carousel-item-padding-40-px"
        customButtonGroup={<ButtonGroup />}
      >
        {category.map((item) => {
          return (
            <div md={4} className="item-container">
              <a href={"/category/" + item.id + "/" + item.title}>
                <div className="num">{item.rating}</div>
                <div className="item-bottom">
                  <Image src={item.icon} className={"item-icon"} />
                  <h3>{item.title}</h3>
                  <span>{item.num}</span>
                </div>
              </a>
            </div>
          );
        })}
      </Carousel>
    </Container>
  );
}
