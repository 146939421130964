import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import subscribe from "../../images/subscribe.png";
import { Button, Input } from "antd";
import email from "../../images/email.png";
import "./index.css";
export default function Index() {
  return (
    <Container className="newsletter-container">
      <Row>
        <Col md={5}>
          <Image src={subscribe} className="subscribe-image" />
        </Col>
        <Col md={7} className="newsletter-second-container">
          <div className="inner-newsletter-container">
            <p className="subscribe-for-offer">Subscribe For Offers</p>
            <h3 className="inner-newsletter-heading">
              Adventures Calling You Guys!
            </h3>
            <p className="newsletter-description">
              The Brilliant reasons Falcon Oasis should be your
              one-stop-travel-partner!
            </p>

            <div className="email-box-container">
              <div className="email-news-letter-container">
                <Input
                  className="antd-email-input"
                  placeholder={"Enter your email"}
                  prefix={
                    <Image
                      src={email}
                      style={{ width: "15px", height: "10px" }}
                    />
                  }
                />
                <Button className="send-now-btn">Send Now!</Button>
              </div>
            </div>
            <p className="newsletter-description" style={{ marginTop: "30px" }}>
              Expect a reply in 2-3 working hours
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
