import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../Component/Header";
import Slider from "../../Component/Slider";
import Category from "../../Component/Category";
import HomeProduct from "../../Component/HomeProduct/index";
import Newsletter from "../../Component/Newsletter/index";
import Help from "../../Component/Help/index";
import cta from "../../images/cta.png";
import Testimonials from "../../Component/Testimonials";
import Footer from "../../Component/Footer/index";
import "./index.css";
export default function Index() {
  return (
    <Container fluid>
      <Header />
      <Slider />
      <Category />
      <HomeProduct
        title={"Trending 2024"}
        description={
          "Brilliant reasons Falcon Oasis should be your one-stop-travel-partner!"
        }
      />
      <HomeProduct
        title={"Dinner Cruise Offers"}
        description={
          "Brilliant reasons Falcon Oasis should be your one-stop-travel-partner!"
        }
      />
      <HomeProduct
        title={"Desert Safari Offers"}
        description={
          "Brilliant reasons Falcon Oasis should be your one-stop-travel-partner!"
        }
      />
      <Container
        style={{ backgroundImage: `url(${cta})` }}
        className="container-cta"
      ></Container>
      <Newsletter />
      <Help />
      <Testimonials
        title={"Our Recent Reviews"}
        description={"what our costumers says about us"}
      />
      <Footer />
    </Container>
  );
}
