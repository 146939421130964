import React from "react";
import Header from "../../../Component/Header";
import Footer from "../../../Component/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, Input, message, Flex, Checkbox } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./index.css";
export default function Index() {
  const [form] = Form.useForm();
  const onFinish = () => {
    message.success("Submit success!");
  };
  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  return (
    <Container fluid>
      <Header />
      <Container className="login-container">
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="username"
                label="Username or email address *"
                rules={[
                  {
                    required: true,
                    message: "Please input your Username or Email",
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password *"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Flex justify="space-between" align="center">
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox className="forgot_password">Remember me</Checkbox>
                  </Form.Item>
                  <a href="" className="forgot_password">
                    Forgot password
                  </a>
                </Flex>
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="btn-login"
                >
                  Log in
                </Button>
                <br></br>
                <br></br>
                <a href="/signup" className="register-now">
                  Register now!
                </a>
              </Form.Item>
            </Form>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}
