import React, { useState, useEffect } from "react";
import "./index.css";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import map from "../../../images/map.png";
import arrow_down from "../../../images/arrow_down.png";
import marker from "../../../images/marker.png";
import { falconApiCalling } from "../../API/falconApiCalling";
import { Divider } from "antd";
import faq_icon from "../../../images/faq_icon.png";
export default function FAQ(props) {
  const { pid } = props;
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(1);
  const getProductItineary = () => {
    let params = { action: "GET_PRODUCT_FAQ", pid: pid };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  useEffect(() => {
    getProductItineary();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md={1}>
          <Image src={faq_icon} />
        </Col>
        <Col md={11} className="faq_header">
          <Row>
            {data.map((item, index) => {
              return (
                <Col md={12} key={index}>
                  <Container
                    className="itineary-top-header"
                    onClick={() => setSelected(index)}
                  >
                    <Row>
                      <Col md={1} className="bubble_holder">
                        <div className="bubble">
                          <span>{index + 1}</span>
                        </div>
                      </Col>
                      <Col md={10} className="itineary_title_holder">
                        <span className="itineary_main_title">
                          {item.question}
                        </span>
                      </Col>
                      <Col md={1} className="arrow_holder">
                        <Image src={arrow_down} className="arrow_icon" />
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Collapse in={index === selected ? true : false}>
                      <div className="inner-collapse">
                        <Divider />
                        <div>
                          <p>{item.answer}</p>
                        </div>
                      </div>
                    </Collapse>
                  </Container>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
