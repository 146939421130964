import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import { Button } from "antd";
export default function Index(props) {
  return (
    <Container className="counter-container">
      <Row>
        <Col md={3}>
          <Button className="counter-btn" onClick={() => props.add()}>
            +
          </Button>
        </Col>
        <Col
          md={3}
          style={{
            display: "flex",
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="seat">{props.value}</span>
        </Col>
        <Col md={2}>
          <Button className="counter-btn" onClick={() => props.minus()}>
            -
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
